.Menu {
    height: 60px;
    width:100%;
    box-shadow: 10px 5px 15px black;
    background-color: #263C4E;
    display: inline-block;
    border: solid;
    color: white;
}

a {
    color:white;
    text-decoration:none;
    line-height: 0px;
    margin-right:5%;
    margin-left:5%;
    font-size: 18px;
}

.LigneMenu {
    text-align: center;
    margin-right: auto;
    margin-left:auto;
    color:white;
}

@media only screen and (max-width: 710px) {
    a {
        color:white;
        text-decoration:none;
        line-height: 0;
        margin-right:5%;
        margin-left:5%;
        font-size: 18px;
    }
}