.TitreT {
    font-weight: bolder;
    text-align: center;
    margin-top: 100px;
    font-size: 30px;
    
}
.CNV, .S {
    display: inline-block;
}

.SC, .SI, .CNVC, .CNVI {
    /* 
    sophro collective
    sophro individuel
    CNV collective
    CVN individuel 
    */
    display: inline-block;
    vertical-align: top;
    margin-right: 30px;
    text-align : center;
    height: 150px;
    width: 300px;
    background-color: white;
    border:solid;
    border-color: #263C4E;
    border-width: 15px 15px 15px 15px;
    margin-top: 20px;
    margin-bottom: 100px;
}



@media only screen and (max-width: 1335px) {
    .SC, .SI {
        vertical-align: top;
        margin-right: 30px;
        text-align : center;
        height: 150px;
        width: 300px;
        background-color: white;
        border:solid;
        border-color: #263C4E;
        border-width: 15px 15px 15px 15px;
        margin-top: 20px;
        margin-bottom: 100px;
    }

    .CNVC, .CNVI {
        vertical-align: top;
        margin-right: 30px;
        text-align : center;
        height: 150px;
        width: 300px;
        background-color: white;
        border:solid;
        border-color: #263C4E;
        border-width: 15px 15px 15px 15px;
        margin-top: 20px;
        margin-bottom: 100px;
    }

    .CNV, .S {
        display: block;
        margin-right:auto;
        margin-left: auto;
    }
}

@media only screen and (max-width: 710px) {
    .SC, .SI, .CNVC, .CNVI {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 10px;
        grid-auto-rows: 200px;
        grid-template-areas:
          ". a a ."
          ". a a .";

        grid-area: a;
        align-self: center;
        justify-self: center;

        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;
        background-color: white;
        width: 300px;
        height: 150px;
        border:solid;
        border-color: #263C4E;
        border-width: 15px 15px 15px 15px;
    }
    

    .CNVI{
        margin-bottom: 100px;

    }

    .Tarifs {
        margin-right:auto;
        margin-left:auto;
    }

}

h5 {
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 15px;
}

.T {
    text-decoration:underline;
    font-size: 17px;
}

.Reglement {
    margin: 0 auto;
    display: table;
}

li {
    list-style-type:square;
    margin-top: 15px;
    text-align: left;
}

.TitreR {
    font-size: 30px;
    margin-bottom:25px;
    text-align: center;
}

h3 {
    text-decoration: underline;
}

.img4 {
    float:left;
    width: 25%;
    border-radius: 5px;
    margin-right: 30px;
}

.Tarifs {
    margin: 0 auto;
    display: table;
}


