.Info2 {
    margin-top: 50px;
    margin-left: 16%;
    margin-right: 16%;
    display: flex;
    flex-direction: row;

}

@media only screen and (max-width: 1000px) {
    .Info2 {
        margin-top: 50px;
        margin-left: 10%;
        margin-right: 10%;
        margin-bottom: 30px;

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .Image2{
        margin-right: 2%;
        border-radius: 5px;
        margin-bottom: 30px;
    }
    
}

@media only screen and (max-width: 720px) {
    .Info2 {
        margin-top: 50px;
        margin-left: 10%;
        margin-right: 10%;
        margin-bottom: 30px;

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .Image2{
        margin-right: 2%;
        border-radius: 5px;
        margin-bottom: 30px;
    }
}

.Image2{
    width: 40%;
    margin-right: 2%;
    border-radius: 5px;
}
.Text {
    text-indent: 40px;
    margin-top : 10px
}