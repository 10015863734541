.Image1 {
    display:block;
    margin-right: auto;
    margin-left: auto;
    width: 55%;
    margin-top: 40px;
    border-radius: 5px;
    margin-bottom: 50px;

    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.Text1 {
    margin-left: 16%;
    margin-right: 16%;
    text-indent: 40px;
    margin-top : 10px
}

.Text0 {
    margin-left: 16%;
    margin-right: 16%;
    text-indent: 40px;
    margin-bottom: -5px;

}
