.Contact {

    margin-left: auto;
    margin-right: auto;
    margin-bottom: 150px;
    margin-top: 30px;
    background-color: #263C4E;
    width : 700px;
    border:solid;
    border-radius: 5px;
    border-color: #263C4E;
    border-width: 15px 200px 15px 200px;
    box-shadow: 5px 5px 15px black;
}

@media only screen and (max-width: 710px) {
    .Contact {

        margin-left: auto;
        margin-right: auto;
        margin-bottom: 100px;
        margin-top: 30px;
        background-color: #263C4E;
        width : 360px;
        border:solid;
        border-radius: 5px;
        border-color: #263C4E;
        border-width: 15px 30px 15px 30px;
        box-shadow: 5px 5px 15px black;
    }
}

.Boutton {
    text-align:center;
}

button {
    margin-top:10px;
    width:100px;
    height: 25px;
    
}

input {
    margin-top:3px;
    width: 300px;
    margin-bottom:10px;
}

textarea{
    width: 300px;
    height: 150px;
    resize: none;
    margin-top:3px;
}

label{
    color: white;

}

.TitreC {
    font-size: 40px;
    text-align: center;
    margin-top: 100px;
}

.DivC {
    margin-left: auto;
    margin-right: auto;
}