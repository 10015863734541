.Header {
    box-shadow: 10px 0px 15px black;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 10px;
    background-color: rgba(256,256,256,256);
    
}

.Logo {
    display: block;
    float: left;
    margin-left: 45%;
    margin-right: 10px;
}

h3 {
    display: block;
    margin-top: 5px;
    
}

.Tel {
    display: block;
    text-align: center;
    list-style-type: none;
    background-color: white;
    margin-right:auto;
    margin-left:auto;

}

@media only screen and (max-width: 710px) {
    .Logo {
        display: block;
        float: left;
        margin-left: 33%;
        margin-right: 10px;
    }
}