.Footer {
    background-color: #263C4E;
    width: 100%;
    height: 60px;
    box-shadow: 15px 15px 15px 15px black;
    border: solid;
    color: white;
    text-align: center;
    margin-top: 50px;
}

p {
    margin-top: 20px;
}

